import { SwiperSlide } from "swiper/react";

export function getSwiperImages(images) {
  return images.map((image, index) => {
    return (
      <SwiperSlide key={index}>
        <img src={image.src} alt={image.alt} />
      </SwiperSlide>
    );
  });
}
