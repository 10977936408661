const getPalette = (theme) => {
  return {
    type: "light",
    primary: { main: "#ac4fc6", hover: "#c584d7" },
    secondary: { main: "#009681", hover: "#4db6a7" },
    accent: "#ac4fc6",
    info: {
      main: "#ac4fc6",
    },
    error: {
      main: "#FF0040",
    },
    white: {
      main: "#FFF",
    },
  };
};

export default getPalette;
