import React from "react";
import Typography from "@mui/material/Typography";
import { menuImages, restaurantImages } from "./gallery";
import { getSwiperImages } from "../../utils/swiper";
import {
  getMerchantAccolades,
  ACCOLADE_FOOD_HYGIENE,
  ACCOLADE_HALAL,
  ACCOLADE_TRIP_ADVISOR_TOP_RATED,
} from "../../utils/merchant";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        Thai food is forging a harmonious blend of the combination flavours of
        sweet, sour, salty and spicy. Our menu features a combination of Thai
        classic and contemporary dishes which design to represent the essence of
        four regions in Thailand. Our food is prepared from fresh ingredients
        for you to enjoy at home.
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return getSwiperImages(menuImages);
}

export function getGalleryRestaurantImages() {
  return getSwiperImages(restaurantImages);
}
